<template>
  <v-container>
    <StoneHeader />
    <div class="order">
      <p class="h1" v-if="error">期限が切れたか有効なURLではありません。</p>
      <p class="h1" v-else>確認しています。</p>
    </div>
    <StoneFooter />
  </v-container>
</template>

<script>
import {loadElepay} from 'elepay-js-sdk'
import StoneHeader from '@/components/organisms/StoneHeader'
import StoneFooter from '@/components/organisms/StoneFooter'

export default {
  name: 'event',

  components: {
    StoneHeader,
    StoneFooter
  },
  data(){
    return {
      error    : '',
    }
  },
  async created(){
    const elepay = await loadElepay('pk_live_c19670be499b357804e55')
    const token = this.$route.params.token
    try{
console.log(this.wc)
      const [order] = await this.wc.get('order', `/w/token,${token}/`)
      console.log(order)
      elepay.checkout(order.code)
    }catch(e){
      console.log(e)
      this.error = true
    }
  },
}
</script>

<style>
</style>
